<template>
  <v-textarea v-bind="$attrs" full-width auto-grow :background-color="color" v-on="$listeners">
  </v-textarea>
</template>
<script>
export default {
  name: 'STextarea',
  inheritAttrs: false,
  props: {
    color: {
      type: String,
      default: 'white',
    },
  },
};
</script>
