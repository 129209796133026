// https://stackoverflow.com/a/53758827/251556
export function seededShuffle<T extends any>(arr: T[], seed: number): T[] {
  const array = [...arr];
  let currentPosition = array.length;
  let item;
  let newIndex;

  // While there remain elements to shuffle…
  while (currentPosition) {
    // Pick a remaining element…
    newIndex = Math.floor(seededRandom(seed) * currentPosition--);

    // And swap it with the current element.
    item = array[currentPosition];
    array[currentPosition] = array[newIndex];
    array[newIndex] = item;
    ++seed;
  }
  return array;
}

export function seededRandom(seed: number) {
  const x = Math.sin(seed++) * 10000;
  return x - Math.floor(x);
}

export function seededRandomBetween(seed: number, min: number, max: number, decimals = 3) {
  const range = max - min;
  return parseFloat((range * seededRandom(seed) + min).toFixed(decimals));
}
